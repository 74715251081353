@charset "UTF-8";
@font-face {
  font-family: 'pbminfotech-base-icons';
  src: url('../fonts/pbminfotech-base-icons53f9.eot?11059985');
  src: url('../fonts/pbminfotech-base-icons53f9.eot?11059985#iefix') format('embedded-opentype'),
       url('../fonts/pbminfotech-base-icons53f9.woff2?11059985') format('woff2'),
       url('../fonts/pbminfotech-base-icons53f9.woff?11059985') format('woff'),
       url('../fonts/pbminfotech-base-icons53f9.ttf?11059985') format('truetype'),
       url('../fonts/pbminfotech-base-icons53f9.svg?11059985#pbminfotech-base-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'pbminfotech-base-icons';
    src: url('../font/pbminfotech-base-icons.svg?11059985#pbminfotech-base-icons') format('svg');
  }
}
*/
[class^="pbmit-base-icon-"]:before, [class*=" pbmit-base-icon-"]:before {
  font-family: "pbminfotech-base-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.pbmit-base-icon-d1:before { content: '\e800'; } /* '' */
.pbmit-base-icon-chat:before { content: '\e801'; } /* '' */
.pbmit-base-icon-calendar:before { content: '\e802'; } /* '' */
.pbmit-base-icon-heart:before { content: '\e803'; } /* '' */
.pbmit-base-icon-link:before { content: '\e804'; } /* '' */
.pbmit-base-icon-quote:before { content: '\e805'; } /* '' */
.pbmit-base-icon-right-open:before { content: '\e806'; } /* '' */
.pbmit-base-icon-left-open:before { content: '\e807'; } /* '' */
.pbmit-base-icon-user:before { content: '\e808'; } /* '' */
.pbmit-base-icon-chat-1:before { content: '\e809'; } /* '' */
.pbmit-base-icon-folder-open:before { content: '\e80a'; } /* '' */
.pbmit-base-icon-tags:before { content: '\e80b'; } /* '' */
.pbmit-base-icon-tumbler:before { content: '\e80c'; } /* '' */
.pbmit-base-icon-search-1:before { content: '\e80d'; } /* '' */
.pbmit-base-icon-location:before { content: '\e80e'; } /* '' */
.pbmit-base-icon-right-big:before { content: '\e80f'; } /* '' */
.pbmit-base-icon-left-quote:before { content: '\e810'; } /* '' */
.pbmit-base-icon-down-open-big:before { content: '\e811'; } /* '' */
.pbmit-base-icon-up-open-big:before { content: '\e812'; } /* '' */
.pbmit-base-icon-cancel:before { content: '\e813'; } /* '' */
.pbmit-base-icon-angle-right:before { content: '\e814'; } /* '' */
.pbmit-base-icon-arrow-right:before { content: '\e815'; } /* '' */
.pbmit-base-icon-aside:before { content: '\e816'; } /* '' */
.pbmit-base-icon-music:before { content: '\e817'; } /* '' */
.pbmit-base-icon-plus:before { content: '\e818'; } /* '' */
.pbmit-base-icon-videocam:before { content: '\e819'; } /* '' */
.pbmit-base-icon-link-1:before { content: '\e81a'; } /* '' */
.pbmit-base-icon-gallery:before { content: '\e81b'; } /* '' */
.pbmit-base-icon-right-small:before { content: '\e81c'; } /* '' */
.pbmit-base-icon-left-small:before { content: '\e81d'; } /* '' */
.pbmit-base-icon-angle-left:before { content: '\e81e'; } /* '' */
.pbmit-base-icon-arrow-left:before { content: '\e81f'; } /* '' */
.pbmit-base-icon-form:before { content: '\e820'; } /* '' */
.pbmit-base-icon-method-draw-image:before { content: '\e821'; } /* '' */
.pbmit-base-icon-marker:before { content: '\e822'; } /* '' */
.pbmit-base-icon-contact:before { content: '\e823'; } /* '' */
.pbmit-base-icon-supermarket-1:before { content: '\e824'; } /* '' */
.pbmit-base-icon-supermarket:before { content: '\e825'; } /* '' */
.pbmit-base-icon-shopping-bag-1:before { content: '\e826'; } /* '' */
.pbmit-base-icon-shopping-bag:before { content: '\e827'; } /* '' */
.pbmit-base-icon-shopping-basket:before { content: '\e828'; } /* '' */
.pbmit-base-icon-menu-1:before { content: '\e829'; } /* '' */
.pbmit-base-icon-arrow-right-1:before { content: '\e82a'; } /* '' */
.pbmit-base-icon-call:before { content: '\e82b'; } /* '' */
.pbmit-base-icon-align-left:before { content: '\e82c'; } /* '' */
.pbmit-base-icon-right-arrow:before { content: '\e82d'; } /* '' */
.pbmit-base-icon-testimonial:before { content: '\e82e'; } /* '' */
.pbmit-base-icon-arrow:before { content: '\e82f'; } /* '' */
.pbmit-base-icon-sticky:before { content: '\e830'; } /* '' */
.pbmit-base-icon-pin-outline:before { content: '\e831'; } /* '' */
.pbmit-base-icon-clock:before { content: '\e832'; } /* '' */
.pbmit-base-icon-phone:before { content: '\e833'; } /* '' */
.pbmit-base-icon-curve-arrow:before { content: '\e834'; } /* '' */
.pbmit-base-icon-placeholder:before { content: '\e835'; } /* '' */
.pbmit-base-icon-clock-1:before { content: '\e836'; } /* '' */
.pbmit-base-icon-email:before { content: '\e837'; } /* '' */
.pbmit-base-icon-user-1:before { content: '\e838'; } /* '' */
.pbmit-base-icon-doctor-stethoscope:before { content: '\e839'; } /* '' */
.pbmit-base-icon-right-arrow-1:before { content: '\e83a'; } /* '' */
.pbmit-base-icon-supermarket-2:before { content: '\e83b'; } /* '' */
.pbmit-base-icon-speech-bubble:before { content: '\e83c'; } /* '' */
.pbmit-base-icon-appointment:before { content: '\e83d'; } /* '' */
.pbmit-base-icon-shopping-cart:before { content: '\e83e'; } /* '' */
.pbmit-base-icon-quote-1:before { content: '\e83f'; } /* '' */
.pbmit-base-icon-chat-2:before { content: '\e840'; } /* '' */
.pbmit-base-icon-letter:before { content: '\e841'; } /* '' */
.pbmit-base-icon-placeholder-1:before { content: '\e842'; } /* '' */
.pbmit-base-icon-star:before { content: '\e843'; } /* '' */
.pbmit-base-icon-calendar-1:before { content: '\e844'; } /* '' */
.pbmit-base-icon-share:before { content: '\e845'; } /* '' */
.pbmit-base-icon-left-arrow:before { content: '\e846'; } /* '' */
.pbmit-base-icon-right-arrow-2:before { content: '\e847'; } /* '' */
.pbmit-base-icon-chat-bubble:before { content: '\e848'; } /* '' */
.pbmit-base-icon-bookmark:before { content: '\e849'; } /* '' */
.pbmit-base-icon-chat-bubble-1:before { content: '\e84a'; } /* '' */
.pbmit-base-icon-phone-volume-solid:before { content: '\e84b'; } /* '' */
.pbmit-base-icon-heart-empty:before { content: '\e84c'; } /* '' */
.pbmit-base-icon-check-solid:before { content: '\e84d'; } /* '' */
.pbmit-base-icon-left-arrow-1:before { content: '\e84e'; } /* '' */
.pbmit-base-icon-next:before { content: '\e84f'; } /* '' */
.pbmit-base-icon-square-dot-:before { content: '\e850'; } /* '' */
.pbmit-base-icon-cart:before { content: '\e851'; } /* '' */
.pbmit-base-icon-calendar-alt-solid:before { content: '\e852'; } /* '' */
.pbmit-base-icon-call-1:before { content: '\e853'; } /* '' */
.pbmit-base-icon-location-1:before { content: '\e854'; } /* '' */
.pbmit-base-icon-forward:before { content: '\e855'; } /* '' */
.pbmit-base-icon-pin:before { content: '\e856'; } /* '' */
.pbmit-base-icon-long-arrow-alt-right-solid:before { content: '\e857'; } /* '' */
.pbmit-base-icon-plus-symbol-button:before { content: '\e858'; } /* '' */
.pbmit-base-icon-left-dir:before { content: '\e859'; } /* '' */
.pbmit-base-icon-right-dir:before { content: '\e85a'; } /* '' */
.pbmit-base-icon-phone-call:before { content: '\e85b'; } /* '' */
.pbmit-base-icon-tick:before { content: '\e85c'; } /* '' */
.pbmit-base-icon-quote-2:before { content: '\e85d'; } /* '' */
.pbmit-base-icon-comment-alt:before { content: '\e85e'; } /* '' */
.pbmit-base-icon-comment:before { content: '\e85f'; } /* '' */
.pbmit-base-icon-close-circular-button-symbol:before { content: '\e860'; } /* '' */
.pbmit-base-icon-check-mark-1:before { content: '\e861'; } /* '' */
.pbmit-base-icon-download-solid:before { content: '\e862'; } /* '' */
.pbmit-base-icon-news:before { content: '\e863'; } /* '' */
.pbmit-base-icon-left-quote-1:before { content: '\e864'; } /* '' */
.pbmit-base-icon-calendar-2:before { content: '\e865'; } /* '' */
.pbmit-base-icon-user-2:before { content: '\e866'; } /* '' */
.pbmit-base-icon-chat-3:before { content: '\e867'; } /* '' */
.pbmit-base-icon-doc:before { content: '\e868'; } /* '' */
.pbmit-base-icon-file:before { content: '\e869'; } /* '' */
.pbmit-base-icon-arroba:before { content: '\e86a'; } /* '' */
.pbmit-base-icon-quote-4:before { content: '\e86b'; } /* '' */
.pbmit-base-icon-right-arrow-3:before { content: '\e86c'; } /* '' */
.pbmit-base-icon-dot:before { content: '\e86d'; } /* '' */
.pbmit-base-icon-dot-1:before { content: '\e86e'; } /* '' */
.pbmit-base-icon-record:before { content: '\e86f'; } /* '' */
.pbmit-base-icon-quote-5:before { content: '\e870'; } /* '' */
.pbmit-base-icon-rhombus:before { content: '\e871'; } /* '' */
.pbmit-base-icon-check-mark:before { content: '\e872'; } /* '' */
.pbmit-base-icon-down-arrow:before { content: '\e873'; } /* '' */
.pbmit-base-icon-document:before { content: '\e874'; } /* '' */
.pbmit-base-icon-quotes:before { content: '\e875'; } /* '' */
.pbmit-base-icon-right-chevron:before { content: '\e876'; } /* '' */
.pbmit-base-icon-back:before { content: '\e877'; } /* '' */
.pbmit-base-icon-blocks-with-angled-cuts-1:before { content: '\e878'; } /* '' */
.pbmit-base-icon-reply-1:before { content: '\e879'; } /* '' */
.pbmit-base-icon-alarm-clock:before { content: '\e87a'; } /* '' */
.pbmit-base-icon-calendar-3:before { content: '\e87b'; } /* '' */
.pbmit-base-icon-user-3:before { content: '\e87c'; } /* '' */
.pbmit-base-icon-play-button:before { content: '\e87d'; } /* '' */
.pbmit-base-icon-quote-3:before { content: '\e87e'; } /* '' */
.pbmit-base-icon-shopping-bag-1:before { content: '\e87f'; } /* '' */
.pbmit-base-icon-right-chevron-2:before { content: '\e880'; } /* '' */
.pbmit-base-icon-exclamation:before { content: '\e881'; } /* '' */
.pbmit-base-icon-warning:before { content: '\e882'; } /* '' */
.pbmit-base-icon-up-arrow:before { content: '\e883'; } /* '' */
.pbmit-base-icon-chevron-right-solid:before { content: '\e884'; } /* '' */
.pbmit-base-icon-phone-auricular-and-clock-delivery-symbol:before { content: '\e885'; } /* '' */
.pbmit-base-icon-quote-6:before { content: '\e886'; } /* '' */
.pbmit-base-icon-pointed-star:before { content: '\e887'; } /* '' */
.pbmit-base-icon-cardiogram:before { content: '\e888'; } /* '' */
.pbmit-base-icon-pdf-file-format-symbol:before { content: '\e889'; } /* '' */
.pbmit-base-icon-approved:before { content: '\e88a'; } /* '' */
.pbmit-base-icon-call-2:before { content: '\e88b'; } /* '' */
.pbmit-base-icon-shopping-cart-1:before { content: '\e88c'; } /* '' */
.pbmit-base-icon-tick-1:before { content: '\e88d'; } /* '' */
.pbmit-base-icon-quote-7:before { content: '\e88e'; } /* '' */
.pbmit-base-icon-asterisk:before { content: '\e88f'; } /* '' */
.pbmit-base-icon-bag:before { content: '\e890'; } /* '' */
.pbmit-base-icon-right-arrow-4:before { content: '\e891'; } /* '' */
.pbmit-base-icon-user-1:before { content: '\e8b8'; } /* '' */
.pbmit-base-icon-pencil:before { content: '\e8b9'; } /* '' */
.pbmit-base-icon-clock-2:before { content: '\e8c4'; } /* '' */
.pbmit-base-icon-check-square-regular:before { content: '\e8cf'; } /* '' */
.pbmit-base-icon-registered-solid:before { content: '\e8d0'; } /* '' */
.pbmit-base-icon-twitter:before { content: '\f099'; } /* '' */
.pbmit-base-icon-right-circled:before { content: '\f0a9'; } /* '' */
.pbmit-base-icon-menu-2:before { content: '\f0c9'; } /* '' */
.pbmit-base-icon-gplus:before { content: '\f0d5'; } /* '' */
.pbmit-base-icon-mail-alt:before { content: '\f0e0'; } /* '' */
.pbmit-base-icon-comment-empty:before { content: '\f0e5'; } /* '' */
.pbmit-base-icon-chat-empty:before { content: '\f0e6'; } /* '' */
.pbmit-base-icon-angle-double-right:before { content: '\f101'; } /* '' */
.pbmit-base-icon-reply:before { content: '\f112'; } /* '' */
.pbmit-base-icon-folder-open-empty:before { content: '\f115'; } /* '' */
.pbmit-base-icon-ok-squared:before { content: '\f14a'; } /* '' */
.pbmit-base-icon-youtube-play:before { content: '\f16a'; } /* '' */
.pbmit-base-icon-instagram:before { content: '\f16d'; } /* '' */
.pbmit-base-icon-flickr:before { content: '\f16e'; } /* '' */
.pbmit-base-icon-paper-plane:before { content: '\f1d8'; } /* '' */
.pbmit-base-icon-pinterest:before { content: '\f231'; } /* '' */
.pbmit-base-icon-vimeo-1:before { content: '\f27d'; } /* '' */
.pbmit-base-icon-reddit:before { content: '\f281'; } /* '' */
.pbmit-base-icon-user-circle:before { content: '\f2bd'; } /* '' */
.pbmit-base-icon-facebook-squared:before { content: '\f308'; } /* '' */
.pbmit-base-icon-linkedin-squared:before { content: '\f30c'; } /* '' */
.pbmit-base-icon-comment-1:before { content: '\f4ac'; } /* '' */
.pbmit-base-icon-search:before { content: '📥'; } /* '\1f4e5' */
